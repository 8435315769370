import React, { useState, useEffect } from 'react'
import { ScrollView, StyleSheet, Text, View, Platform, useWindowDimensions, TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useQuery, useMutation } from '@apollo/client'
import { Ionicons } from '@expo/vector-icons'
import { Calendar, LocaleConfig } from 'react-native-calendars'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { FIND_MANY_SCHEDULE, FIND_MANY_REQUEST, UPDATE_ONE_SCHEDULE } from '../gqls'

import Loading from '../components/Loading'
import Window from '../components/Window'

dayjs.locale('ru')
dayjs.extend(customParseFormat)

LocaleConfig.locales['ru'] = {
    monthNames: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
    ],
    monthNamesShort: [
        'Янв',
        'Февр',
        'Март',
        'Апр',
        'Май',
        'Июнь',
        'Июль',
        'Авг',
        'Сент',
        'Окт',
        'Нояб',
        'Дек'
    ],
    dayNames: [
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота'
    ],
    dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    today: 'Сегодня'
}

LocaleConfig.defaultLocale = 'ru'

const CloseScreen = ({ route }) => {
    const { id } = route.params
    const navigation = useNavigation()
    const now = dayjs()
    const { width } = useWindowDimensions()
    const dynamicWidth = width > 1024 ? 1024 : width - 32

    const [currentDate, setCurrentDate] = useState(null)
    const [date, setDate] = useState('')
    const [month, setMonth] = useState(parseInt(now.format('MM')))
    const [year, setYear] = useState(now.format('YYYY'))

    const [monthDates, setMonthDates] = useState([])

    const [selectedDate, setSelectedDate] = useState(null)
    const [picker, setPicker] = useState(false)

    const [closes, setCloses] = useState([])

    const [updateOneScheduleMutation] = useMutation(UPDATE_ONE_SCHEDULE)

    const { data: findManyScheduleData, loading: findManyScheduleLoading } = useQuery(FIND_MANY_SCHEDULE, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                objectId: {
                    equals: id
                },
                status: {
                    equals: true
                }
            }
        },
        pollInterval: 1000
    })

    const { data: findManyRequestData, loading: findManyRequestLoading } = useQuery(FIND_MANY_REQUEST, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                objectId: {
                    equals: id
                },
                status: {
                    notIn: ['3', '4']
                },
            }
        },
        pollInterval: 1000
    })

    useEffect(() => {
        setCurrentDate(now.format('YYYY-MM-DD'))
    }, [])

    useEffect(() => {
        const dates = new Array(dayjs(`${year}-${month}-01`, { format: 'YYYY-MM-DD' }).daysInMonth()).fill(null).map((x, i) => dayjs(`${year}-${month}-01`, { format: 'YYYY-MM-DD' }).startOf('month').add(i, 'days').format('YYYY-MM-DD'))
        setMonthDates(dates)
    }, [month, year])

    if (!currentDate || findManyScheduleLoading || findManyRequestLoading) return <Loading />

    const { findManySchedule } = findManyScheduleData
    const { findManyRequest } = findManyRequestData

    const disableSchedule = monthDates.filter(schedule => now.format('YYYY-MM-DD') === schedule || now.isBefore(schedule)).reduce((acc, current) => {
        if ((dayjs(current) >= dayjs('2025-05-01') && dayjs(current) <= dayjs('2025-05-02')) || (dayjs(current) >= dayjs('2025-05-08') && dayjs(current) <= dayjs('2025-05-09'))) {
            acc[current] = {
                disabled: true,
                disableTouchEvent: true
            }

            const week = findManySchedule.filter(schedule => (schedule.week.some(week => week.toLowerCase() === dayjs(current).format('dddd'))))
            
            const days = findManySchedule.filter(schedule => (schedule.days.some(day => day === dayjs(current).format('DD.MM.YYYY'))))
            if (days.length !== 0) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: false,
                    marked: true,
                    dotColor: '#66ff00'
                }
            }
            const requests = findManyRequest.filter(request => request.reservedAt === current)
            if (requests.length > 0 && days.length > 0 && requests.length !== days.length) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: false,
                    marked: true,
                    dotColor: 'yellow'
                }
            }
            const closes = findManySchedule.filter(schedule => schedule.closes.some(close => close === dayjs(current).format('DD.MM.YYYY')))
            if (closes.length > 0 && days.length > 0 && days.length > closes.length) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: false,
                    marked: true,
                    dotColor: 'yellow'
                }
            }
            if (requests.length > 0 && days.length > 0 && requests.length === days.length) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: true,
                    marked: true,
                    dotColor: 'red'
                }
            }
            if (requests.length > 0 && requests.some(request => request.schedule.fullday)) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: true,
                    marked: true,
                    dotColor: 'red'
                }
            }
            if (closes.length > 0 && days.length > 0 && closes.length >= days.length) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: true,
                    marked: true,
                    dotColor: 'red'
                }
            }
            if (closes.length >= 2) {
                let count_day = 0;
                let count_night = 0;
                closes.forEach(schedule => {
                    console.log(schedule.name)
                    if (schedule.name.toLowerCase().includes('день'))
                        count_day = 1;
                    if (schedule.name.toLowerCase().includes('ночь') || schedule.name.toLowerCase().includes('вечер'))
                        count_night = 1;
                })
                if (count_day + count_night == 2)
                    acc[current] = {
                        disabled: false,
                        disableTouchEvent: true,
                        marked: true,
                        dotColor: 'red'
                    };
            }
            if (closes.some(schedule => schedule.fullday)) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: true,
                    marked: true,
                    dotColor: 'red'
                };
            }
            if (requests.length > 0 && days.length > 0 && closes.length > 0 && (requests.length + closes.length) === days.length) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: true,
                    marked: true,
                    dotColor: 'red'
                }
            }
            const openDays = findManySchedule.filter(schedule =>
                schedule.days.some(d => d === dayjs(current).format('DD.MM.YYYY'))
            );
            let schedules = null;
            if (openDays.length > 0)
                schedules = findManySchedule.filter(schedule => (schedule.days.some(object => object === dayjs(current).format('DD.MM.YYYY'))))
            else {
                schedules = []
            }
            //если закрыт полный день то тарифы не показывает
            if (schedules.find(schedule => {
                const closeSchedule = closes.find(close => schedule.id === close.id)
                if (closeSchedule?.fullday)
                    return closeSchedule;
                else
                    return null;
            }) != null)
                schedules = []

            //если день и ночь закрыты то тарифы не показываю
            if (closes.length >= 2) {
                let count_day = 0;
                let count_night = 0;
                closes.forEach(schedule => {
                    console.log(schedule.name)
                    if (schedule.name.toLowerCase().includes('день'))
                        count_day = 1;
                    if (schedule.name.toLowerCase().includes('ночь') || schedule.name.toLowerCase().includes('вечер'))
                        count_night = 1;
                })
                if (count_day + count_night == 2)
                    schedules = []
            }

            //если заявка полный день то сразу закрывает тариф
            if (requests.length > 0 && requests.some(request => { return request.schedule != null && request.schedule.fullday })) {
                schedules = []
            }

            const currentSchedules = schedules//.filter((schedule) => (schedule.days.some(day => day === dayjs(selectedDate).format('DD.MM.YYYY'))))
                .filter(schedule => !requests.some(request => schedule.fullday || request.scheduleId === schedule.id && !schedule.fullday)) //если полный день или 
                //если выбранное дата есть в списке закрытых дат то не показывает
                .filter(schedule => !schedule.closes.some(close => close === dayjs(current).format('DD.MM.YYYY')))
                //если день или ночь закрыты то сутки не показываю
                .filter(schedule => {
                    let count_day = 0;
                    let count_night = 0;
                    closes.forEach(schedule => {
                        if (schedule.name.toLowerCase().includes('день'))
                            count_day = 1;
                        if (schedule.name.toLowerCase().includes('ночь') || schedule.name.toLowerCase().includes('вечер'))
                            count_night = 1;
                    })
                    if (count_day + count_night == 0)
                        return schedule
                    else {
                        if (!schedule.fullday)
                            return schedule
                    }
                })

            if (currentSchedules.length == 0) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: true,
                    marked: true,
                    dotColor: 'red'
                };
            }
            return acc
        } else {
            acc[current] = {
                disabled: true,
                disableTouchEvent: true
            }

            const week = findManySchedule.filter(schedule => (schedule.week.some(week => week.toLowerCase() === dayjs(current).format('dddd'))))
            if (week.length !== 0) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: false,
                    marked: true,
                    dotColor: '#66ff00'
                }
            }
            const days = findManySchedule.filter(schedule => (schedule.days.some(day => day === dayjs(current).format('DD.MM.YYYY'))))
            if (days.length !== 0) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: false,
                    marked: true,
                    dotColor: '#66ff00'
                }
            }
            const requests = findManyRequest.filter(request => request.reservedAt === current)
            if ((requests.length > 0 && days.length > 0 && requests.length !== days.length) || (requests.length > 0 && week.length > 0 && days.length === 0 && requests.length !== week.length)) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: false,
                    marked: true,
                    dotColor: 'yellow'
                }
            }
            const closes = findManySchedule.filter(schedule => schedule.closes.some(close => close === dayjs(current).format('DD.MM.YYYY')))
            if ((closes.length > 0 && days.length > 0 && days.length > closes.length) || (closes.length > 0 && week.length > 0 && days.length === 0 && week.length > closes.length)) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: false,
                    marked: true,
                    dotColor: 'yellow'
                }
            }
            if ((requests.length > 0 && days.length > 0 && requests.length === days.length) || (requests.length > 0 && week.length > 0 && days.length === 0 && requests.length === week.length)) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: true,
                    marked: true,
                    dotColor: 'red'
                }
            }
            if (requests.length > 0 && requests.some(request => request.schedule.fullday)) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: true,
                    marked: true,
                    dotColor: 'red'
                }
            }
            if ((closes.length > 0 && days.length > 0 && closes.length >= days.length) || (closes.length > 0 && week.length > 0 && days.length === 0 && closes.length >= week.length)) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: true,
                    marked: true,
                    dotColor: 'red'
                }
            }
            if (closes.length >= 2) {
                let count_day = 0;
                let count_night = 0;
                closes.forEach(schedule => {
                    console.log(schedule.name)
                    if (schedule.name.toLowerCase().includes('день'))
                        count_day = 1;
                    if (schedule.name.toLowerCase().includes('ночь') || schedule.name.toLowerCase().includes('вечер'))
                        count_night = 1;
                })
                if (count_day + count_night == 2)
                    acc[current] = {
                        disabled: false,
                        disableTouchEvent: true,
                        marked: true,
                        dotColor: 'red'
                    };
            }
            if (closes.some(schedule => schedule.fullday)) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: true,
                    marked: true,
                    dotColor: 'red'
                };
            }
            if ((requests.length > 0 && days.length > 0 && closes.length > 0 && (requests.length + closes.length) === days.length) || (requests.length > 0 && week.length > 0 && closes.length > 0 && days.length === 0 && (requests.length + closes.length) === week.length)) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: true,
                    marked: true,
                    dotColor: 'red'
                }
            }
            const openDays = findManySchedule.filter(schedule =>
                schedule.days.some(d => d === dayjs(current).format('DD.MM.YYYY'))
            );
            let schedules = null;
            if (openDays.length > 0)
                schedules = findManySchedule.filter(schedule => (schedule.days.some(object => object === dayjs(current).format('DD.MM.YYYY'))))
            else {
                schedules = findManySchedule.filter(schedule => (schedule.days.some(object => object === dayjs(current).format('DD.MM.YYYY')) || schedule.week.some(object => object.toLowerCase() === dayjs(current).format('dddd'))))
            }
            //если закрыт полный день то тарифы не показывает
            if (schedules.find(schedule => {
                const closeSchedule = closes.find(close => schedule.id === close.id)
                if (closeSchedule?.fullday)
                    return closeSchedule;
                else
                    return null;
            }) != null)
                schedules = []

            //если день и ночь закрыты то тарифы не показываю
            if (closes.length >= 2) {
                let count_day = 0;
                let count_night = 0;
                closes.forEach(schedule => {
                    console.log(schedule.name)
                    if (schedule.name.toLowerCase().includes('день'))
                        count_day = 1;
                    if (schedule.name.toLowerCase().includes('ночь') || schedule.name.toLowerCase().includes('вечер'))
                        count_night = 1;
                })
                if (count_day + count_night == 2)
                    schedules = []
            }

            //если заявка полный день то сразу закрывает тариф
            if (requests.length > 0 && requests.some(request => { return request.schedule != null && request.schedule.fullday })) {
                schedules = []
            }

            const currentSchedules = schedules//.filter((schedule) => (schedule.days.some(day => day === dayjs(selectedDate).format('DD.MM.YYYY'))))
                .filter(schedule => !requests.some(request => schedule.fullday || request.scheduleId === schedule.id && !schedule.fullday)) //если полный день или 
                //если выбранное дата есть в списке закрытых дат то не показывает
                .filter(schedule => !schedule.closes.some(close => close === dayjs(current).format('DD.MM.YYYY')))
                //если день или ночь закрыты то сутки не показываю
                .filter(schedule => {
                    let count_day = 0;
                    let count_night = 0;
                    closes.forEach(schedule => {
                        if (schedule.name.toLowerCase().includes('день'))
                            count_day = 1;
                        if (schedule.name.toLowerCase().includes('ночь') || schedule.name.toLowerCase().includes('вечер'))
                            count_night = 1;
                    })
                    if (count_day + count_night == 0)
                        return schedule
                    else {
                        if (!schedule.fullday)
                            return schedule
                    }
                })

            if (currentSchedules.length == 0) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: true,
                    marked: true,
                    dotColor: 'red'
                };
            }
            return acc
        }
    }, {})

    const requests = findManyRequest.filter(request => request.reservedAt === selectedDate)
    // const schedules = findManySchedule.filter(schedule => (schedule.days.some(object => object === dayjs(selectedDate).format('DD.MM.YYYY')) || schedule.week.some(object => object.toLowerCase() === dayjs(selectedDate).format('dddd'))) && schedule.status == true)
    const closesAr = findManySchedule.filter(schedule => schedule.closes.some(object => object === dayjs(selectedDate).format('DD.MM.YYYY')))

    const openDays = findManySchedule.filter(schedule =>
        schedule.days.some(d => d === dayjs(selectedDate).format('DD.MM.YYYY'))
    );
    let schedules = null
    if (openDays.length > 0)
        schedules = findManySchedule.filter(schedule => (schedule.days.some(day => day === dayjs(selectedDate).format('DD.MM.YYYY'))))
    else {
        schedules = findManySchedule.filter(schedule => (schedule.days.some(day => day === dayjs(selectedDate).format('DD.MM.YYYY')) || schedule.week.some(week => week.toLowerCase() === dayjs(selectedDate).format('dddd'))))
    }

    //если закрыт полный день то тарифы не показывает
    if (schedules.find(schedule => {
        const closeSchedule = closesAr.find(close => schedule.id === close.id)
        if (closeSchedule?.fullday)
            return closeSchedule;
        else
            return null;
    }) != null)
        schedules = []
    //если день и ночь закрыты то тарифы не показываю
    if (closesAr.length >= 2) {
        let count_day = 0;
        let count_night = 0;
        closesAr.forEach(schedule => {
            console.log(schedule.name)
            if (schedule.name.toLowerCase().includes('день'))
                count_day = 1;
            if (schedule.name.toLowerCase().includes('ночь') || schedule.name.toLowerCase().includes('вечер'))
                count_night = 1;
        })
        if (count_day + count_night == 2)
            schedules = []
    }

    //если заявка полный день то сразу закрывает тариф
    if (requests.length > 0 && requests.some(request => { return request.schedule != null && request.schedule.fullday })) {
        schedules = []
    }

    const currentSchedules = schedules
        //.filter((object) => (object.days.length > 0 || !schedules.some(schedule => schedule.days.length > 0)) && object.status == true)
        // .filter((object) => (object.status == true))
        .filter(() => !requests.some(request => request.schedule.fullday)).filter(schedule => !requests.some(request => schedule.fullday || request.scheduleId === schedule.id))
        //если выбранное дата есть в списке закрытых дат то не показывает
        .filter(schedule => !schedule.closes.some(object => object === dayjs(selectedDate).format('DD.MM.YYYY')))
        .filter(schedule => !closesAr.some(fullday => schedule.fullday))
        //если день или ночь закрыты то сутки не показываю
        .filter(schedule => {
            let count_day = 0;
            let count_night = 0;
            closesAr.forEach(schedule => {
                if (schedule.name.toLowerCase().includes('день'))
                    count_day = 1;
                if (schedule.name.toLowerCase().includes('ночь') || schedule.name.toLowerCase().includes('вечер'))
                    count_night = 1;
            })
            if (count_day + count_night == 0)
                return schedule
            else {
                if (!schedule.fullday)
                    return schedule
            }
        })
        .sort((a, b) => a.weightSort - b.weightSort)

    const onSubmit = async () => {
        const closesSchedules = findManySchedule.filter(object => closes.includes(object.id))
        for (const schedule of closesSchedules) {
            await updateOneScheduleMutation({
                variables: {
                    where: {
                        id: schedule.id
                    },
                    data: {
                        closes: [...schedule.closes, date]
                    }
                }
            })
        }
        if (navigation.canGoBack()) {
            navigation.goBack()
        } else {
            window.history.back()
        }
    }

    const onChangeDate = (value) => {
        setDate(value)
    }

    const onShowPicker = () => {
        setPicker(prev => !prev)
        setSelectedDate(null)
        setCloses([])
    }

    const onClear = () => {
        setSelectedDate(null)
        setDate('')
    }

    return (
        <>
            <ScrollView style={styles.container} contentContainerStyle={{ marginTop: 16 }}>
                <Window style={{ paddingHorizontal: 16 }}>
                    <Text style={styles.title}>Дата</Text>
                    <View style={{ marginBottom: 24 }}>
                        <TouchableOpacity activeOpacity={1} onPress={onShowPicker} style={[styles.input, { alignItems: 'center', justifyContent: 'space-between' }]} >
                            {
                                date ? (
                                    <>
                                        <Text>{date}</Text>
                                        <TouchableOpacity onPress={onClear}>
                                            <Ionicons color='#667085' size={20} name='close' />
                                        </TouchableOpacity>
                                    </>
                                ) : <Text style={{ color: '#667085' }}>Выберите дату</Text>
                            }
                        </TouchableOpacity>
                        {
                            picker ? (
                                <Calendar
                                    disabledByDefault
                                    disableAllTouchEventsForDisabledDays
                                    firstDay={1}
                                    current={currentDate}
                                    theme={{
                                        selectedDayBackgroundColor: '#BCC8B2',
                                        todayTextColor: '#000'
                                    }}
                                    renderArrow={(direction) => <Ionicons size={20} name={direction === 'left' ? 'ios-chevron-back' : 'ios-chevron-forward'} />}
                                    onDayPress={day => {
                                        const now = dayjs()
                                        if (now.isBefore(day.dateString) || now.isSame(day.dateString, 'day')) {
                                            setSelectedDate(day.dateString)
                                            setCurrentDate(day.dateString)
                                            setPicker(false)
                                            onChangeDate(dayjs(day.dateString).format('DD.MM.YYYY'))
                                        }
                                    }}
                                    markedDates={{
                                        ...disableSchedule,
                                        [selectedDate]: { selected: true, disableTouchEvent: true, selectedDotColor: 'orange' }
                                    }}
                                    onMonthChange={(date) => {
                                        setMonth(date.month)
                                        setYear(date.year)
                                    }}
                                />
                            ) : null
                        }
                    </View>
                    {
                        selectedDate ? (
                            <>
                                <Text style={styles.title}>График</Text>
                                {
                                    currentSchedules.map((schedule, index) => (
                                        <TouchableOpacity
                                            onPress={() => {
                                                if (closes.some(close => close === schedule.id)) {
                                                    setCloses(prev => [...prev.filter(close => close !== schedule.id)])
                                                } else {
                                                    setCloses(prev => [...prev, schedule.id])
                                                }
                                            }}
                                            key={index}
                                            style={[{ backgroundColor: closes.some(close => close === schedule.id) ? '#BCC8B2' : '' },
                                            {
                                                marginBottom: 24,
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                borderRadius: 8,
                                                borderWidth: 1,
                                                borderColor: '#D0D5DD',
                                                padding: 16,
                                            }]}
                                        >
                                            <View style={styles.timeContainer}>
                                                <Text style={[styles.title, { maxWidth: dynamicWidth - 120 }]}>{schedule.name}</Text>
                                                <View style={styles.time}>
                                                    <Text style={styles.timeText}>{schedule.startAt} - {schedule.endAt}</Text>
                                                    <Text style={styles.timeText}>{(schedule.discount && schedule.days.includes(dayjs(selectedDate).format('DD.MM.YYYY'))) ? schedule.discount : schedule.price} ₽</Text>
                                                </View>
                                            </View>
                                            <View>
                                                <Text>{closes.some(close => close === schedule.id) ? 'Отменить' : 'Выбрать'}</Text>
                                            </View>
                                        </TouchableOpacity>
                                    ))
                                }
                            </>
                        ) : null
                    }
                </Window>
            </ScrollView>
            <Window>
                <View style={styles.footer}>
                    <TouchableOpacity onPress={onSubmit} style={styles.button}>
                        <Text style={styles.buttonText}>Применить</Text>
                    </TouchableOpacity>
                </View>
            </Window>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        fontSize: 16,
        fontWeight: '500',
        marginBottom: 8
    },
    input: {
        height: 44,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        borderRadius: 4,
        paddingHorizontal: 10,
        flexDirection: 'row'
    },
    line: {
        marginVertical: 12
    },
    footer: {
        borderTopWidth: 1,
        borderColor: '#D0D5DD',
        padding: 16
    },
    clearButton: {
        height: 46,
        borderWidth: 1,
        borderColor: '#101828',
        borderRadius: 6,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 12
    },
    clearButtonText: {
        fontSize: 16,
        fontWeight: '500',
        color: '#101828'
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#101828',
    },
    buttonText: {
        color: '#FCFCFD',
        fontWeight: '500'
    },
    timeContainer: {
        alignItems: 'flex-start'
    },
    time: {
        height: 60,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 16
    },
    timeText: {
        fontSize: 16,
    },
})

export default CloseScreen